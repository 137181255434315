@use "../../../styles/variables";
@use "../../../styles/mixins";

.wrapper {
  min-height: 100vh;
  padding-top: 200px;
  background-color: variables.$color-gray-light;
}

.loading_container {
  min-height: 100vh;
  background-color: variables.$color-gray-light;
  display: grid;
  place-content: center;
}

.header {
  color: variables.$color-gray-dark;
  font-size: 48px;
  font-weight: 400;
  letter-spacing: 1.92px;
  text-transform: uppercase;
}

.header_cards_row {
  margin: 56px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  .header_column {
    font-weight: 300;

    &:nth-child(2) {
      border-left: 2px solid #dfdede;
      border-right: 2px solid #dfdede;
    }

    h3 {
      font-size: 20px;
      margin-bottom: 12px;
      color: variables.$color-gray-dark;
      font-weight: 400;
    }

    p {
      font-size: 16px;
      letter-spacing: 1px;
      color: #6b6b6b;
    }
  }

  @media screen and (max-width: 800px) {
    grid-template-columns: 1fr;
    margin: 20px 0 32px 0;

    .header_column {
      padding: 0;

      &:first-child,
      &:last-child,
      &:nth-child(2) {
        padding: 20px 0;
      }

      &:nth-child(2) {
        border: 0;
        border: 0;
        border-top: 2px solid #dfdede;
        border-bottom: 2px solid #dfdede;
      }
    }
  }
}

.content_row {
  display: flex;
  gap: 40px;

  @media screen and (max-width: 1220px) {
    flex-direction: column-reverse;
  }
  padding-bottom: 60px;
}

.content {
  width: 100%;
}

.recomend_container {
  width: 100%;
  // margin-top: 24px;
  // padding: 16px;
  border-radius: 12px;
  background: variables.$color-white;
  display: flex;
  gap: 24px;

  &__image_wrapper {
    position: relative;
    width: 250px;
    // height: 300px;
    height: auto;
    border-radius: 8px;
    overflow: hidden;
    flex-shrink: 0;
  }

  &__content {
    padding: 16px 40px 16px 0px;
    // height: 150px;
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;

    .name_head {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .name {
      font-size: 25px;
      line-height: 28px;
      font-weight: 400;
      color: variables.$color-gray-dark;
      position: relative;
      text-transform: capitalize;
    }

    .jets {
      margin-top: 16px;
      color: #9b9b9b;
    }

    .list {
      list-style: none;
      // padding: 32px 0 24px 0;
      margin-top: 16px;
      // margin-bottom: 24px;
      display: flex;
      // flex-direction: column;
      gap: 8px;

      .list_item {
        color: #9b9b9b;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 16px;
        position: relative;

        &:not(:last-child)::after {
          content: "|";
          padding-right: 8px;
        }
      }

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
    }

    .divider {
      margin-top: 24px;
      margin-bottom: 24px;
      border-top: #ccc;
    }

    .footer {
      justify-self: flex-end;
      display: flex;
      gap: 16px;
      // flex-direction: column;
      justify-content: space-between;
      align-items: center;

      .info_card {
        max-width: 250px;
        // border: 1px solid #aaa;
        // border-radius: 8px;
        // padding: 8px 16px;
        background-color: white;
        color: #9b9b9b;
        font-size: 12px;
        color: #dc4c64;
      }

      button {
        outline: 0;
        border: 2px solid variables.$color-primary;
        background-color: transparent;
        font-weight: 500;

        color: variables.$color-primary;
        padding: 8px 20px;
        padding-top: 10px;
        border-radius: 50px;
      }

      @media screen and (max-width: 768px) {
        .info_card {
          max-width: 200px;
        }
      }
    }
  }

  @media screen and (max-width: 550px) {
    flex-direction: column;

    &__image_wrapper {
      height: 250px;
      width: 100%;
    }

    &__content {
      padding: 24px;
      padding-top: 0;

      .name_head {
        align-items: flex-start;

        p {
          margin-top: 4px;
        }
      }

      .footer {
        .info_card {
          max-width: 150px;
        }
      }
    }
  }

  @media screen and (max-width: 400px) {
    &__content {
      .footer {
        gap: 8px;
        flex-direction: column-reverse;

        button {
          width: 100%;
        }

        .info_card {
          max-width: 100%;
        }
      }
    }
  }
}

.summary {
  width: 100%;
  max-width: 381px;
  border-radius: 12px;
  background: variables.$color-white;
  height: auto;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;

  .title {
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 8px;

    span {
      color: #9b9b9b;
      font-size: 14px;
    }
  }

  &__info {
    // margin-top: 24px;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    &:not(:last-child) {
      margin-bottom: 24px;
    }

    hr {
      margin-top: 8px;
      border-top: #ccc;
    }

    .routes {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      gap: 16px;

      .airport {
        width: 140px;
        .code {
          font-size: 16px;
          color: #6b6b6b;
        }

        .fullName {
          font-size: 10px;
          color: #9b9b9b;
        }
      }
    }
  }

  @media screen and (max-width: 1220px) {
    max-width: 100%;
  }
}

.time_pax {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #6b6b6b;
  font-size: 12px;

  .time {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
  }

  .pax {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
  }
}

.btn_container {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  flex-grow: 1;
}

.button {
  margin-top: 16px;
  width: 100%;
  display: inline-block;
  margin-left: auto;
  padding: 8px;
  background-color: variables.$color-primary;
  outline: 0;
  border: 0;
  color: white;
  text-align: center;
  border-radius: 4px;
}
